
.view-script-main{
    width: 100%;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 100px);
    .view-script-content{
        display: flex;
        flex-direction: column;
        flex: 1;
        width: 100%;
        border-radius: 10px;
        background-color: #fff;
        .view-script-list {
            flex: 1;
            margin: 10px 0;
            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
            }
            .view-script-item{
                padding: 30px 30px 0;
                .view-script-item-top{
                    display: flex;
                    align-items: center;
                    .view-script-img{
                        width: 392px;
                        height: 226px;
                        margin-right: 20px;
                        img{
                            max-width: 100%;
                            height: 226px;
                        }
                    }
                    .view-script-right{
                        flex: 1;
                        width: 1%;
                        .view-script-name{
                            font-size: 20px;
                            color: #333;
                        }
                        .view-script-fenlei{
                            margin: 40px 0;
                            font-size: 16px;
                            color: #666;
                        }
                        .view-script-jianjie{
                            font-size: 16px;
                            color: #666;
                        }
                    }
                }
                .view-script-body{
                    display: flex;
                    flex-direction: column;
                    border: 2px solid #574DED;
                    box-shadow: 0px 10px 0px 0px #ECEBFF;
                    border-radius:25px;
                    margin:42px 0;
                    .view-script-body-title{
                        width: 160px;
                        height: 32px;
                        background: #584EEE;
                        border-radius: 16px;
                        line-height: 32px;
                        text-align: center;
                        color: #fff;
                        margin: -12px 0 0 24px;
                        position: relative;
                        &:before{
                            content: "";
                            background: #fff;
                            -webkit-border-radius: 50%;
                            border-radius: 50%;
                            width: 8px;
                            height: 8px;
                            position: absolute;
                            top: 12px;
                            left: 20px
                        }
                        &:after{
                            content: "";
                            background: #fff;
                            -webkit-border-radius: 50%;
                            border-radius: 50%;
                            width: 8px;
                            height: 8px;
                            position: absolute;
                            top: 12px;
                            right: 20px
                        }
                    }
                    .view-script-body-content{
                        display: flex;
                        min-height: 100px;
                        padding-left: 22px;
                    }
                }
            }
        }
    }
    ::v-deep.el-table{
        .warning-row {
            background: #ECEBFF;
        }
    }
}
